import React, { useState } from "react";
import { baseUrl } from "../../../constants/AppContants";
import Video from "../../../components/shared/video";
import cn from "classnames";
import btnImagesm from "../../../data/images/others/btn-signup-sm.webp";
import btnImagelg from "../../../data/images/others/btn-signup.webp";

// eslint-disable-next-line react/prop-types
const HeroArea = ({ data }: any) => {
  const sizeStyle: any = {
    md: `leading-11 h-12 w-32 sm:h-15 sm:w-40 sm:leading-12`,
    lg: `text-22base h-73 w-230 leading-73`,
    xl: `h-15 w-50 text-xl leading-3`,
  };
  const shapeStyle: any = {
    rounded: `rounded`,
    square: `rounded-4xl`,
    square20xl: `rounded-20`,
    square2xl: `rounded-2xl`,
    square22xl: `rounded-22`,
  };
  const colorStyle: any = {
    primary: `bg-primary`,
    secondary: `bg-secondary`,
  };

  const btnstyle: any = {
    size: "lg",
    shape: "square22xl",
    color: "primary",
  };
  const buttonClasses = cn(
    "video_popup w-28 h-28 bg-primary rounded-full flex items-center justify-center mx-auto",
    "font-exo",
    "inline-block",
    "text-center",
    "font-bold",
    "group",
    "hover:opacity-80",
    sizeStyle[btnstyle["size"]],
    shapeStyle[btnstyle["shape"]],
    colorStyle[btnstyle["color"]]
  );

  const btnImageSm = {
    backgroundImage: `url(${btnImagesm})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const btnImageLg = {
    backgroundImage: `url(${btnImagelg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  let video_arr, video_id, video_channel;
  let video_link = "https://www.youtube.com/watch?v=eS9Qm4AOOBY";
  video_arr = video_link.split("=", -1);
  video_id = video_arr[1];
  video_channel = video_link.split(".")[1];
  const [isOpen, setOpen] = useState(false);
  console.log(data);
  return (
    <section className="banner-section relative sm:h-540 md:h-650 lg:h-780 xl:h-940 bg-no-repeat bg-center bg-cover flex items-center">
      <div className="container px-4 z-10">
        <div className="text-white mt-16">
          <p className="text-base lg:text-md font-bold mb-6 sm:mb-10 uppercase heading">
            Tuthastu's
          </p>
          <h2 className="mb-6 sm:mb-10 hero-banner-font">
            <img src="/logo.svg" height="50" alt="spacetantra" />
          </h2>
          <p className="banner-text">It's a story of love, acceptance, believing and forgiving on a land unknown, in a journey untaught. It's when Siya and Veer lost connection with their father in a space encounter, their adventure begins on an unknown land in search for their dad.</p>

          {/* <div className="flex-shrink-0 lg:w-52 justify-center mb-6 lg:mb-0 text-center upcoming_play_video">
            <span
              style={btnImageLg}
              className={buttonClasses}
              role="button"
              onClick={() => setOpen(true)}
              onKeyPress={(e) => console.log(e)}
            >
              Play Now
              <img className="ml-2" src="/arrrow-icon.webp" alt="" />
            </span>
          </div> */}
          {/* {data?.buttons &&
                        data.buttons.map(({ id, content, ...props }) => (
                            <Button key={id} {...props} className="text-white">
                                {content}
                                <StaticImage
                                    className="align-middle ml-3 transition-all group-hover:ml-5"
                                    src="../../../data/images/icons/arrrow-icon.webp"
                                    alt=""
                                />
                            </Button>
                            http://localhost:1337${item.attributes.Background_image.data.attributes.url}
                        ))} */}
        </div>
      </div>
      {/* <div className="absolute right-0.5 bottom-0">
        <img
          className="w-96 md:w-650 xl:w-auto"
          // eslint-disable-next-line react/prop-types
          src={`${baseUrl}${data[0].attributes.Png_image.data.attributes.url}`}
          alt=""
        />
      </div> */}
      {/* <Video
        channel={video_channel}
        videoId={video_id}
        isOpen={isOpen}
        setOpen={setOpen}
      /> */}
      <div className="absolute bgvideo">
        <video
          muted
          autoPlay
          loop
          id="ebook-vdo"
          className="dwnload-vdo"
          preload="auto"
          poster="Poster.png"
        >
          <source
            src={`${baseUrl}${data[0].attributes.Banner_video.data.attributes.url}`}
            type="video/mp4"
          />
          <track
            src="captions_en.vtt"
            kind="captions"
            srcLang="en"
            label="english_captions"
          />
        </video>
      </div>
    </section>
  );
};
export default HeroArea;
