import React, { Fragment } from "react";
import Button from "../../../components/shared/button";
import {
  getClosest,
  getSiblings,
  slideToggle,
  slideUp,
} from "../../../utils/mobile-nav-utils";
import PropTypes from "prop-types";
const MobileNavMenu = ({ ofcanvasOpen, ofcanvasHandaler }) => {
  const social_info = [
    // {
    //   id: "1",
    //   icon: "icofont-facebook",
    //   link: "https://www.facebook.com/spacetantra",
    //   title: "facebook",
    // },
    {
      id: "2",
      icon: "icofont-instagram",
      link: "https://www.instagram.com/space.tantra/",
      title: "instagram",
    },
    {
      id: "3",
      icon: "icofont-youtube-play",
      link: "https://www.youtube.com/channel/UCc9KofdI-KHZUwNrnpmrqfQ",
      title: "youtube",
    },
    // {
    //   id: "4",
    //   icon: "icofont-twitter",
    //   link: "https://twitter.com/Spacetantra1",
    //   title: "twitter",
    // },
  ];
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("menu-expand") ||
      target.classList.contains("menu-expand")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 500);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 500);
        }
      });
    }
  };

  return (
    <div
      className={`${
        ofcanvasOpen ? "mobile-menu-open" : ""
      } fixed invisible top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto opacity-0 transition-all`}
    >
      <div
        className="OffCanvasContent fixed left-0 top-0 z-20 opacity-0 invisible h-full w-full bg-black"
        onClick={ofcanvasHandaler}
        onKeyDown={ofcanvasHandaler}
        role="button"
        tabIndex={0}
      ></div>
      <div
        className="OffCanvasContent"
        onClick={ofcanvasHandaler}
        onKeyDown={ofcanvasHandaler}
        role="button"
        tabIndex={0}
      />
      <div
        className="site-mobile-menu transform -translate-x-full 
            transition-all text-black bg-white z-30 
            relative h-full px-8 py-8 w-310 sm:w-96 overflow-x-hidden overflow-y-auto"
      >
        <button
          onClick={ofcanvasHandaler}
          onKeyDown={ofcanvasHandaler}
          className=" flex justify-end items-center ml-auto"
        >
          Close <i className="icofont-close"></i>
        </button>
        <ul className="mt-10 mb-10">
          {social_info.map((menu) => {
            return (
              <li
                key={`menu-${menu.id}`}
                className="relative font-medium block pb-3 mb-3 border-b"
              >
                <a
                  href={menu.link}
                  className={`${menu.title}-style mr-3 last:mr-0 hw-40`}
                  target="_blank"
                >
                  <i className={menu.icon}></i>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

MobileNavMenu.propTypes = {
  MobilemenuData: PropTypes.array,
  ofcanvasOpen: PropTypes.bool,
  ofcanvasHandaler: PropTypes.func,
};

export default MobileNavMenu;
