const WelcomeFeatures = ({
  title,
  description,
  iconImage,
  bgShapImage,
  video,
}: any) => {
  return (
    <div className="py-15 px-12 relative mt-10">
      <div className="content relative z-10">
        <video
          muted
          autoPlay
          loop
          id="ebook-vdo"
          className="dwnload-vdo"
          preload="auto"
          poster="Poster.png"
        >
          <source src={video} type="video/mp4" />
          <track
            src="captions_en.vtt"
            kind="captions"
            srcLang="en"
            label="english_captions"
          />
        </video>
        <h3 className="font-bold text-white mt-3 mb-4">{title}</h3>
        <p className="text-white leading-7">{description}</p>
      </div>
      <div className="absolute inset-0 z-0 bg-center">
        <img src={bgShapImage} alt={title} />
      </div>
    </div>
  );
};
export default WelcomeFeatures;
