import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Logo from "../../logo";

const FooterInfoWidget = ({ infoData }) => {
  const social_info = [
    // {
    //   id: "1",
    //   icon: "icofont-facebook",
    //   link: "https://www.facebook.com/spacetantra",
    //   title: "facebook",
    // },
    {
      id: "2",
      icon: "icofont-instagram",
      link: "https://www.instagram.com/space.tantra/",
      title: "instagram",
    },
    {
      id: "3",
      icon: "icofont-youtube-play",
      link: "https://www.youtube.com/channel/UCc9KofdI-KHZUwNrnpmrqfQ",
      title: "youtube",
    },
    // {
    //   id: "4",
    //   icon: "icofont-twitter",
    //   link: "https://twitter.com/Spacetantra1",
    //   title: "twitter",
    // },
  ];
  return (
    <Fragment>
      <Logo />
      <p className="mt-7">{infoData?.text}</p>
      <div className="footer_social flex mt-8">
        {social_info.map((item) => (
          <a
            key={item.id}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`${item.title}-style h-10 w-10 leading-10 text-center mr-3 last:mr-0`}
          >
            <i className={item.icon}></i>
          </a>
        ))}
      </div>
    </Fragment>
  );
};
FooterInfoWidget.propTypes = {
  infoData: PropTypes.shape({
    socials: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};
export default FooterInfoWidget;
