const { protocol, hostname, port } = window.location;
export const frontendUrl =
  hostname === "localhost"
    ? `${protocol}//${hostname}:${port}`
    : `${protocol}//${hostname}`;

/* Set it to false if need localhost backend connectivity */
const development = false;

let baseUrl = "http://localhost:1337";
let apiUrl = "http://localhost:1337/api";

if (hostname === "spacetantra.com") {
  /* For Production Server */
  apiUrl = "https://admin.spacetantra.com/api";
  baseUrl = "https://admin.spacetantra.com";
} else if (hostname === "dev.spacetantra.com" || development) {
  /* For Development Server */
  baseUrl = "https://dev-admin.spacetantra.com";
  apiUrl = "https://dev-api.spacetantra.com";
}

export { baseUrl, apiUrl };
