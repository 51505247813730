const SectionTitle = ({
  heading,
  description,
  align,
  color,
  showDescription,
}: any) => {
  const title = {
    showDescription: showDescription || false,
    align: align || "center",
    color: color || "primary",
  };
  return (
    <div className="section-title">
      <div className="container">
        <div className="mx-auto text-center">
          <h2 className="font-bold ">{heading}</h2>
          {title.showDescription && (
            <p className="mt-2 leading-7 text-18base ">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default SectionTitle;
