import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../../../components/shared/button";
import SectionTitle from "../../../components/title";
import cn from "classnames";
import btnImagesm from "../../../data/images/others/btn-signup-sm.webp";
import btnImagelg from "../../../data/images/others/btn-signup.webp";
import axios from "axios";
import { apiUrl } from "../../../constants/AppContants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactFormArea = () => {
  const sizeStyle = {
    md: `leading-11 h-12 w-32 sm:h-15 sm:w-40 sm:leading-12`,
    lg: `text-22base h-73 w-230 leading-73`,
    xl: `h-15 w-50 text-xl leading-3`,
  };
  const shapeStyle = {
    rounded: `rounded`,
    square: `rounded-4xl`,
    square20xl: `rounded-20`,
    square2xl: `rounded-2xl`,
    square22xl: `rounded-22`,
  };
  const colorStyle = {
    primary: `bg-primary`,
    secondary: `bg-secondary`,
  };
  const btnstyle = {
    size: "lg",
    shape: "square22xl",
    color: "primary",
  };
  const buttonClasses = cn(
    "font-exo",
    "inline-block",
    "text-center",
    "font-bold",
    "group",
    "hover:opacity-80",
    sizeStyle[btnstyle["size"]],
    shapeStyle[btnstyle["shape"]],
    colorStyle[btnstyle["color"]]
  );

  const btnImageSm = {
    backgroundImage: `url(${btnImagesm})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const btnImageLg = {
    backgroundImage: `url(${btnImagelg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeEmail = (e) => {
    setEmail(e.target.value);
  };
  const changeDescription = (e) => {
    setDescription(e.target.value);
  };
  const form = useRef();
  const handleSubmit = (e) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    e.preventDefault();
    axios
      .post(`${apiUrl}/sendemails`, {
        data: {
          Name: name,
          User_email: email,
          Description: description,
        },
      })
      .then((response) => {
        toast("Thank you for getting in touch!");
        setDescription("");
        setEmail("");
        setName("");
      });
  };
  return (
    <section className="contact-us-form-section pt-16 md:pt-20">
      <div className="container">
        <div className="section-title mb-15">
          <SectionTitle
            heading="GET IN TOUCH"
            description="When unknown printer took type and scrambled it to make type specimen book centuries,"
            showDescription={true}
            color={null}
            align="center"
          />
        </div>
        <form ref={form} onSubmit={handleSubmit}>
          <div className="grid gap-x-8 grid-cols-2">
            <div className="single-fild">
              <input
                className="px-6 h-14 mb-6 border-secondary-90 bg-secondary-100 hover:border-primary transition-all border-2 border-solid block rounded-md w-full focus:outline-none"
                type="text"
                placeholder="Name"
                onChange={changeName}
                name="Name"
                value={name}
              />
            </div>
            <div className="single-fild">
              <input
                className="px-6 h-14 mb-6 border-secondary-90 bg-secondary-100 hover:border-primary transition-all border-2 border-solid block rounded-md w-full focus:outline-none"
                type="email"
                placeholder="E-mail"
                onChange={changeEmail}
                name="Email"
                value={email}
                required
              />
            </div>
            <div className="single-fild col-span-2">
              <textarea
                className="px-6 pt-4 h-72 mb-6 border-secondary-90 bg-secondary-100 hover:border-primary transition-all border-2 border-solid block rounded-md w-full focus:outline-none"
                type="text"
                placeholder="Write from here"
                onChange={changeDescription}
                name="Description"
                value={description}
                required
              />
            </div>
          </div>
          <div className="section-title-warp text-center mt-10">
            <input
              style={{
                backgroundImage: `url(/btn-signup.webp)`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className={buttonClasses}
              role="button"
              type="submit"
              onKeyPress={(e) => console.log(e)}
              value="SUBMIT"
            />
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </section>
  );
};
export default ContactFormArea;
