import React, { useEffect, useState } from "react";
import HeroArea from "../container/home/hero";
import WelcomeFeaturesArea from "../container/home/welcome-features";
import WatchLiveStremingArea from "../container/home/watch-live-streaming";
import MatchArea from "../container/home/match";
import axios from "axios";
import { apiUrl } from "../constants/AppContants";
import BodyImage from "../data/images/others/body-bg.webp";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../assets/css/icofont.min.css";
import "../assets/css/modal-video.min.css";
import ContactFormArea from "../container/contact-us/contact-form";

const IndexPage = () => {
  const [characterResponse, setCharacterResponse] = useState();
  const [episodeResponse, setEpisodeResponse] = useState();
  const [trailerResponse, setTrailerResponse] = useState();
  // const [postResponse, setPostResponse] = useState();
  const [bannerResponse, setBannerResponse] = useState();
  useEffect(() => {
    axios.get(`${apiUrl}/characters?populate=*`).then((response: any) => {
      setCharacterResponse(response.data.data);
    });
    axios.get(`${apiUrl}/episodes?populate=*`).then((response: any) => {
      setEpisodeResponse(response.data.data);
    });
    axios.get(`${apiUrl}/trailers?populate=*`).then((response: any) => {
      setTrailerResponse(response.data.data);
    });
    // axios.get(`${apiUrl}/api/posts?populate=*`).then((response: any) => {
    //   setPostResponse(response.data.data);
    // });
    axios.get(`${apiUrl}/banner-images?populate=*`).then((response: any) => {
      setBannerResponse(response.data.data);
    });
  }, []);

  return (
    <div
      className="wrapper"
      style={{
        backgroundImage: `url(${BodyImage})`,
      }}
    >
      <Header />
      {bannerResponse && <HeroArea data={bannerResponse} />}
      {trailerResponse && <WatchLiveStremingArea data={trailerResponse} />}
      {characterResponse && <WelcomeFeaturesArea data={characterResponse} />}
      {episodeResponse && <MatchArea data={episodeResponse} />}
      <ContactFormArea />
      {/* <FunfactArea data={content["funfact-section"]} />
            <PopulerGamesArea
                data={{
                    ...content["populer-games-section"],
                    items: data.allGames.nodes,
                }}
            /> */}
      {/* {postResponse && (
                <TestimonialArea
                    data={content["testimonial-section"]}
                    data2={postResponse}
                />
            )} */}
      {/* <LatestBlogArea
                data={{
                    ...content["latest-section"],
                    items: data.latestPosts.nodes,
                }}
            /> */}
      <Footer />
    </div>
  );
};

export default IndexPage;
