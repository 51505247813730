import { Player, ControlBar, BigPlayButton } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { baseUrl } from "../../../constants/AppContants";

// eslint-disable-next-line react/prop-types
const WatchLiveStremingArea = ({ data }) => {
  return (
    <section className="watch-live-section py-4 md:py-14">
      <div className="container">
        <div className="section-title mb-15">
          <h2 className="text-center">WATCH TRAILER</h2>
        </div>
        <div className="relative videowrap">
          {/* <Player
            src={`${baseUrl}${data[0].attributes.Video.data[0].attributes.url}`}

            autoPlay={true}
          ></Player> */}

          <video
            muted
            autoPlay
            controls
            loop
            id="ebook-vdo"
            className="dwnload-vdo"
            preload="auto"
            poster="Poster.png"
          >
            <source
              src={`${baseUrl}${data[0].attributes.Video.data[0].attributes.url}`}
              type="video/mp4"
            />
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
          </video>
        </div>
        {/* {data?.items &&
                            data?.items.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <LiveStreaming
                                        title={
                                            item?.liveStreaming?.headings[0]
                                                .content
                                        }
                                        image={
                                            item?.liveStreaming?.images[0].src
                                        }
                                        video_link={item?.liveStreaming?.link}
                                    />
                                </SwiperSlide>
                            ))} */}
      </div>
    </section>
  );
};
export default WatchLiveStremingArea;
