const GamingUpdateArea = () => {
  return (
    <section className="gaming-update-section">
      {/* <div className="container">
                <GamingUpdateCard />
            </div> */}
    </section>
  );
};

export default GamingUpdateArea;
