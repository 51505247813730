import SectionTitle from "../../../components/title";
import WelcomeFeatures from "../../../components/welcome-features";
import { baseUrl } from "../../../constants/AppContants";
// eslint-disable-next-line react/prop-types
const WelcomeFeaturesArea = ({ data }: any) => {
  return (
    <section className="pt-16 md:pt-22 character-section">
      <div className="container px-4">
        <SectionTitle
          heading="MEET THE FAMILY"
          align="center"
          color={null}
          description="When unknown printer took type and scrambled it to make type specimen book centuries,"
          showDescription={false}
        />
        <div className="flex flex-wrap -mx-3 text-center">
          {data &&
            // eslint-disable-next-line react/prop-types
            data.map((item: any) => (
              <div key={item.id} className="w-full md:w-1/2 lg:w-1/3 px-4">
                <WelcomeFeatures
                  title={item.attributes.Name}
                  iconImage={`${baseUrl}${item.attributes.Image.data[0].attributes.url}`}
                  description={item.attributes.Description}
                  bgShapImage={`${baseUrl}${item.attributes.Background_image.data.attributes.url}`}
                  video={`${baseUrl}${item.attributes.Video.data.attributes.url}`}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default WelcomeFeaturesArea;
