import MatchItem from "../../../components/match";
import SectionTitle from "../../../components/title";
import { baseUrl } from "../../../constants/AppContants";

// eslint-disable-next-line react/prop-types
const MatchArea = ({ data }: any) => {
  return (
    <section className="upcoming-gaming-section pb-14 md:pb-16">
      <div className="container">
        <div className="section-title mb-15">
          <SectionTitle
            heading="WATCH EPISODES"
            align="center"
            color={null}
            description="Unravel the secrets of outer world living through stories to enhance your child’s imagination"
            showDescription={true}
          />
        </div>
        {data &&
          // eslint-disable-next-line react/prop-types
          data?.map((item: any) => (
            <MatchItem
              key={item.id}
              title={item.attributes.Name}
              date={item.attributes.Serial_Number}
              image={`${baseUrl}${item.attributes.Image.data.attributes.url}`}
              video_link={item.attributes.Link}
              description={item.attributes.Description}
            />
          ))}
        {/* <div className="more-match text-center">
                    {data?.buttons &&
                        data.buttons.map(({ id, content, ...props }) => (
                            <Button
                                key={id}
                                {...props}
                                className="text-white mt-15"
                            >
                                {content}
                            </Button>
                        ))}
                </div> */}
      </div>
    </section>
  );
};
export default MatchArea;
