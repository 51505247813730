import React from "react";

const Logo = () => {
  return (
    <div className="middle-align">
      <img src="/logo.svg" alt="spacetantra" />
    </div>
  );
};
export default Logo;
